import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"

const Formatwrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #000;
  min-height: 50vh;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Formatwrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </Formatwrapper>
  </Layout>
)

export default NotFoundPage
